import { StartEditingCellParams } from 'ag-grid-community';

import ChartWidget from './chart_widget';
import { create_echarts } from './high_chart';
interface CustomOptions{
	colors?:string[]|string[][],
	type?:string
	y_axis_fields?:{y_field:string, denominator?:string, percent_title:string}[]
	data?:{labels:string[], datasets:{name:string, values:number[]}[]}
	[key:string]:any
}
interface ChartDoc{
	value_based_on_multiple:{field:string, denominator?:string, percent_title?:string, doctype:string}[],
	chart_type:string,
	custom_options?:string,
}
interface Percent{
	field:StartEditingCellParams,
	denominator?:string,
	percentTitle?:string,
	fieldIndex:number,
	denominatorIndex:number
}
const { make_chart } = frappe.utils;
const custom_make_chart = function(wrapper:string, custom_options:CustomOptions = {}, chart_doc:ChartDoc){
	const isGroupBy = chart_doc.chart_type==='Group By'&&(custom_options.type==='line'||custom_options.type==='bar');
	if (!(isGroupBy|| custom_options.type === 'bcwp')){
		make_chart(wrapper, custom_options);
		return;
	}
	const percentArr = getPercents(chart_doc, custom_options);
	const series = getSeries(custom_options);
	const {minColumn, maxColumn, minLine, maxLine} = getRangeData(series.flat());
	const yAxisOption={
		minColumn,
		maxColumn,
		minLine,
		maxLine,
	};
	const yAxis = getYAxis(custom_options, yAxisOption);

	const legend = getLegend(series.flat());
	const chart_args:any={
		title: {
			text: null,
		},
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'cross',
				link:[{
					yAxisIndex:'all',
					xAxisIndex:'all',
				}],
			},
			formatter:tooltipFormat(percentArr, custom_options),
		},
		yAxis,
		xAxis: {
			type:'category',
			data: custom_options.data?.labels||[],
		},
		legend,
		series:series.flat(),
	};

	for (let key in custom_options) {
		if (key==='colors'&&Array.isArray(custom_options.colors)){
			const colors = custom_options.colors.flat();
			if (colors.length !== 0){
				chart_args.color = colors;
			}
			continue;
		}
		if (typeof chart_args[key] === 'object' && typeof custom_options[key] === 'object') {
			chart_args[key] = Object.assign(chart_args[key], custom_options[key]);
		} else {
			chart_args[key] = custom_options[key];
		}
	}
	chart_args.color = chart_args?.color?.filter(Boolean);
	if (!chart_args.color ||chart_args.color?.length === 0) {
		delete chart_args.color;
	}
	wrapper.style.width=wrapper.clientWidth;
	wrapper.style.height = '400px';
	return create_echarts(wrapper, chart_args);
};

function tooltipFormat(percentArr:Percent[], custom_options:CustomOptions){
	return (params:any, ticket:string, chart:any)=>{
		let s = `${params[0]?.name}</br>`;
		params.forEach((item, index)=>{
			const name = item.seriesName;
			const {value} = item;
			 s = `${s}<span style="color:${item.color}">${name}:${value.toFixed(2)}</span><br/>`;
			 if (custom_options.type === 'line'||custom_options.type === 'bar'){
				const percentOption = percentArr.find(each=>each.fieldIndex === index);
				if (!percentOption){ return; }
				if (percentOption.denominatorIndex>-1){
					const columnPercentValue = params[percentOption.denominatorIndex]?.value;
					const columnPercent = columnPercentValue&&columnPercentValue>0?`${(value/columnPercentValue*100).toFixed(2)}%`:'';
					s = `${s}<span style="color:${item.color}">${percentOption.percentTitle}:${columnPercent}</span><br/>`;
				}
			 }
			if (index%2===1&&custom_options.type === 'bcwp'){
				const rowIndex = Math.floor(index/2);
				const percentOption = percentArr.find(each=>each.fieldIndex === rowIndex);
				if (!percentOption){ return; }
				if (percentOption.denominatorIndex>-1){
					const percentIndex = percentOption.denominatorIndex*2;
					const columnPercentValue = params[percentIndex]?.value;
					const linePercentValue = params[percentIndex+1]?.value;
					const columnPercent = columnPercentValue&&columnPercentValue>0?`${(params[index-1].value/columnPercentValue*100).toFixed(2)}%`:'';
					const linePercent = linePercentValue&&linePercentValue>0?`${(value/linePercentValue*100).toFixed(2)}%`:'';
					s = `${s}<span style="color:${item.color}">${percentOption.percentTitle}:${columnPercent}</span><br/>`;
					s = `${s}<span style="color:${item.color}">累计${percentOption.percentTitle}:${linePercent}</span><br/>`;
				}
			}
		});
		return s;
	};
}
function getPercents(chart_doc:ChartDoc, custom_options:CustomOptions){
	const percentOptions = chart_doc.value_based_on_multiple?.length>0?chart_doc.value_based_on_multiple:custom_options.y_axis_fields||[];
	const percentArr:Percent[] = percentOptions.map((item, index)=>{
		const field = item.field||item.y_field;
		const denominatorIndex = percentOptions.findIndex(each=>(each.field||each.y_field)=== item.denominator);
		return {
			field,
			denominator:item.denominator,
			percentTitle:item.percent_title,
			fieldIndex:index,
			denominatorIndex,
		};
	});
	return percentArr;
}
function getSeries(custom_options:CustomOptions){
	const series = custom_options.data?.datasets.map((item, index) => {
		const lineData:number[] = [];
		for (let i = 0; i < item.values.length; i++) {
			const arr = item.values.slice(0, i + 1);
			const result = arr.reduce((pre, next) => pre + next, 0);
			lineData.push(result);
		}
		const line_data = {
			yAxisIndex: 1,
			name: `${__(item.name)}累计`,
			type: 'line',
			data: lineData,
			symbol: 'none',
		};
		const column_data = {
			yAxisIndex: 0,
			name: __(item.name),
			type: custom_options.type==='line'?'line':'bar',
			data: item.values,
			symbol: 'none',
		};
		if (custom_options.type==='line'||custom_options.type==='bar'){
			return [column_data];
		}
		return [column_data, line_data];
	});
	return series||[];
}
function getYAxis(custom_options:CustomOptions,
	yAxisOption:{minColumn:number,
	maxColumn:number,
	minLine:number,
	maxLine:number}){
	const yAxis:any[] = [
		{
			type: 'value',
			name:  __('Chart Current Amount'),
			min:yAxisOption.minColumn,
			max:yAxisOption.maxColumn,
			splitNumber:5,
			interval:(yAxisOption.maxColumn - yAxisOption.minColumn)/5,
		},
	];
	if (custom_options.type === 'bcwp'){
		yAxis.push({
			type: 'value',
			'name': __('Chart Cumulative Quantity'),
			min:yAxisOption.minLine,
			max:yAxisOption.maxLine,
			splitNumber:5,
			interval:(yAxisOption.maxLine - yAxisOption.minLine)/5,

		});
	}
	return yAxis;
}

function getRangeData(series:any[]){
	const columnDataArr = series
		.filter(item=>item.yAxisIndex===0)
		.map(item=>item.data)
		.filter(item=>item!==null&&item!==undefined)
		.flat();
	const minColumn = Math.min(...columnDataArr);
	const maxColumn = Math.max(...columnDataArr);

	const lineDataArr = series
		.filter(item=>item.yAxisIndex===1)
		.map(item=>item.data)
		.filter(item=>item!==null&&item!==undefined)
		.flat();
	const minLine = Math.min(...lineDataArr);
	const maxLine = Math.max(...lineDataArr);
	return {minColumn, maxColumn, minLine, maxLine};
}
function getLegend(series:{name:string}[]){
	const data = series.map(item=>item.name);
	return {
		// Try 'horizontal'
		show:true,
		orient: 'horizontal',
		// left: 0,
		top: 'bottom',
		zlevel:3,
		data,
	  };
}
frappe.utils.make_chart = custom_make_chart;


frappe.widget.widget_factory.chart = ChartWidget;
